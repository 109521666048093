import React from "react";
import styled from "styled-components";

const WrapperTitle = styled.h2`
  @media (max-width: 768px) {
    text-align: center;
  }
`;
export default ({ title, children }) => {
  return (
    <div>
      {title && <WrapperTitle>{title}</WrapperTitle>}
      <div>{children}</div>
    </div>
  );
};
