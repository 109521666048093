import React from "react";
import ContentBlock from "../components/Content/ContentBlock";
import styled from "styled-components";
import PageTitle from "../components/PageTitle/PageTitle";

const ItalicsSpan = styled.span`
  font-style: italic;
`;
export default () => {
  return (
    <div>
      <PageTitle title="About me" url="http://alexdhaenens.com/about" />
      <ContentBlock>
        <p>
          Hey, I'm Alex! Let me introduce myself in a couple words: I'm a
          passionate software engineer living in Ghent. Let me also tell you a
          secret: one of my geatest ambitions is to share my passion with the
          world.
          <br />
          My passsion and therefor also my journey into software engineering
          started a long, long time ago. It all started around the time I was 14
          years old, which is when we started using a calculator with coding
          capabilities (a Texas Instrument). Not long after I got mine, during
          one of my
          <ItalicsSpan> "I'm bored, let's try something new!" </ItalicsSpan>
          moments, I tried coding on that thing. As you can guess, I was
          fascinated by it! It didn't take me more than 3 month to move to
          development on a pc.
        </p>
        <p>
          Around the age of 17, after years of experimenting and self study, I
          had to decide what to study in college/university. I was still bitten
          and therefor studied 4 years Industrial Engineering Informatics
          followed by 2 years of Civil Engineering Computer Science. After my
          studies I obviously went working in a IT company. I also wanted to
          share my passion with others, which is the reason why I teach other
          people different (IT related) subjets, why I have a blog and why I am
          a speaker on different talks.
        </p>
        <p>
          Altough software development is my passion, I have other hobbies as
          well. One of those hobbies is doing sports (running and swimming),
          something I do almost everyday! I also draw comics since I was around
          14, but with the only difference between then and now being that I
          moved from physical drawings to full digital (on my Ipad). Besides
          from those things, I love playing (advanced) board & roleplaying
          games, such as Terraforming marce, d&d etc. Lastly, there are two
          things that have my outspoken love: tea & neck ties.
        </p>
      </ContentBlock>
    </div>
  );
};
