import React from "react";
import styled from "styled-components";
import SEO from "../SEO/SEO";

const WrapperTitle = styled.h1`
  @media (max-width: 768px) {
    text-align: center;
  }
`;
export default ({ title, url }) => {
  return (
    <>
      <SEO title={title} url={url} />
      <WrapperTitle>{title}</WrapperTitle>
    </>
  );
};
